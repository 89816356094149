@import "../../utility/base.scss";
.#{$Prefix}-hero {
  box-sizing: border-box;
  height: 536px;
  position: relative;
  font-size: 0;
  &__inner {
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 0 20px;
  }
}
@media screen and (max-width: $maxWidth + 160) {
  .#{$Prefix}-hero {
    &__inner {
      max-width: $minWidth;
    }
  }
}