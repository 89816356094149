@import "../../utility/css/var.scss";

.#{$mPrefix}-footer {
  background-color: #000000;

  &__inner {
    font-size: 0;
    max-width: $maxWidth;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 60px 20px 60px 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__left {
    flex-grow: 1;
    .#{$mPrefix}-footer {
      &__desc {
        margin-bottom: 36px;
      }
    }
  }

  &__right {
    margin-bottom: 64px;
    .#{$mPrefix}-footer {
      &__desc {
        margin-bottom: 16px;
      }
    }
  }

  &__vcode {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    padding: 2px;
    font-size: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 36px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__copyright {
    flex-basis: 100%;
    font-size: 12px;
    line-height: 30px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    a {
      color: inherit;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 60px;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.9);
  }

  &__desc {
    font-size: 14px;
    max-width: 450px;
    color: rgba(255, 255, 255, 0.9);
  }

  &__telephone {
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      margin-right: 12px;
      background-image: url("./img/icon-phone.svg");
    }
    span {
      font-size: 24px;
      color: #fff;
    }
    &::before,
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__sidebar {
    position: fixed;
    bottom: 120px;
    right: 20px;
    z-index: 99;
    .contact-btn {
      &::before {
        display: block;
        content: "";
        width: 16px;
        height: 17px;
        margin-bottom: 5px;
        position: relative;
        left: -2px;
        background-image: url("./img/icon-service.svg");
      }
      display: block;
      width: 44px;
      padding: 13px 13px;
      border-radius: 25px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      line-height: 18px;
      background-color: #242933;
      border: 2px solid #242933;
      box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
        inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
    }
    .gotop-btn {
      cursor: pointer;
      width: 44px;
      height: 44px;
      line-height: 52px;
      margin-top: 20px;
      text-align: center;
      background-color: #242933;
      border: 2px solid #242933;
      box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
        inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
      border-radius: 50%;
      &::after {
        width: 16px;
        height: 16px;
        content: "";
        position: relative;
        top: 3px;
        display: inline-block;
        transform: rotate(180deg);
        background-image: url("./img/icon-arrow.svg");
      }
    }
  }

  @media screen and (max-width: $maxWidth + 160) {
    &__inner {
      max-width: $minWidth;
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    &__inner {
      display: block;
      padding: 32px 16px;
      text-align: center;
    }
    &__left,
    &__right,
    &__copyright {
      width: 100%;
      display: block;
      text-align: center;
    }
    &__left {
      border-bottom: solid 1px #141922;
      padding-bottom: 30px;
      margin-bottom: 30px;
      .#{$mPrefix}-footer {
        &__desc {
          margin-bottom: 30px;
        }
      }
    }
    &__right {
      margin-bottom: 50px;
      .#{$mPrefix}-footer {
        &__desc {
          display: none;
        }
      }
    }
    &__title {
      font-size: 20px;
      line-height: 28px;
    }
    &__desc {
      font-size: 12px;
      margin: 0 auto;
    }
    &__btn {
      .#{$Prefix}-button {
        width: 100%;
        color: #0052d9;
        border-color: #ffffff;
        background: #ffffff;
      }
    }
    &__vcode {
      margin: 20px auto 40px auto;
    }
    &__telephone {
      display: block;
      height: 44px;
      line-height: 44px;
      border-radius: 4px;
      background-color: #0052d9;
      span {
        font-size: 14px;
      }
      &::before {
        content: "咨询热线：";
        width: auto;
        height: auto;
        font-size: 14px;
        color: #fff;
        margin-right: 0;
        background-image: none;
        background-color: transparent;
      }
    }
    &__sidebar {
      display: none;
    }
  }
}
