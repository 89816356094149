html {
  font-family: "pingfang SC", "helvetica neue", arial, "hiragino sans gb",
    "microsoft yahei ui", "microsoft yahei", simsun, sans-serif;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent; //是一个没有标准化的属性，能够设置点击链接的时候出现的高亮颜色。显示给用户的高光是他们成功点击的标识，以及暗示了他们点击的元素。初始值	black
  word-break: break-all;
  word-break: break-word;
  word-wrap: break-word;
  scroll-behavior: smooth;
}
body {
  -webkit-font-smoothing: antialiased; //平滑像素级别的字体，而不是子像素。从亚像素渲染切换到黑暗背景上的浅色文本的抗锯齿使其看起来更轻
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
body,
button,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
li,
ol,
p,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}
