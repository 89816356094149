@import "./../../utility/css/var.scss";
// page
.about-page {
  // banner 部分
  &-hero {
    background-image: url("./../../mock/img/about/banner.png");
    background-size: cover;
    background-position: center bottom;
    height: 800px;
    &-info {
      position: absolute;
      top: 330px;
      max-width: 800px;
      &__title {
        font-size: 48px;
        color: #ffffff;
        line-height: 64px;
        font-weight: 500;
      }
      &__desc {
        margin-top: 64px;
        font-size: 20px;
        color: #ffffff;
        line-height: 36px;
        p + p {
          margin-top: 30px;
        }
      }
    }
    &-introduce {
      padding: 28px 20px 32px;
      background-color: #000000;
      color: #fff;
      text-align: justify;
      font-size: 14px;
      p + p {
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 768px) {
      height: 300px;
      background-image: url("./../../mock/img/about/banner-m.png");
      &-info {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        text-align: center;
        &__title {
          font-size: 16px;
        }
        &__desc {
          display: none;
        }
      }
    }
  }

  // 简介
  &-introduce {
    &__inner {
      max-width: 1460px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 60px 20px;
      display: flex;
    }
    &__left {
      width: 700px;
      margin-right: 70px;
    }
    &__right {
      flex: 1;
    }
    &__title {
      font-size: 28px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 26px;
    }
    &__desc {
      font-size: 16px;
      color: #4b5b76;
      line-height: 28px;
    }

    @media screen and (max-width: 768px) {
      &__inner {
        padding: 30px 16px;
        display: block;
      }
      &__left {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
        img {
          max-width: 100%;
          display: block;
          height: auto;
        }
      }
      &__title {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 36px;
        margin-bottom: 8px;
      }
      &__desc {
        font-size: 14px;
        color: #4b5b76;
        line-height: 22px;
      }
    }
  }
}

//大事记
.about-time {
  background: #000000;
  background-image: url("./../../mock/img/about/time-bg.png");
  background-position: center center;
  background-size: cover;
  .#{$Prefix}-section__title {
    color: #fff;
  }
}
// section
.about-section--carouselHonor {
  background-image: url("./../../mock/img/about/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}
