@import "../../utility/css/var.scss";
.#{$mPrefix}-mediaRecommend {
  .#{$Prefix}-media {
    &--card {
      padding: 20px;
      height: 100%;
    }
    &__info {
      &-title {
        font-size: 16px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .#{$mPrefix}-mediaRecommend {
    .#{$Prefix}-media {
      &--card {
        padding: 20px;
      }
      &__img {
        margin-left: 20px;
        img {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
}
