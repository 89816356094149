@import "../../utility/css/var.scss";
.#{$mPrefix}-carouselCustomer {
  .#{$Prefix}-media--horizon .#{$Prefix}-media__img img {
    width: 40px;
  }
  .#{$Prefix}-media__info-desc {
    color: #fff;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: -16px;
      top: -10px;
      width: 54px;
      height: 40px;
      background-image: url("./img/adorn.svg");
      background-size: cover;
    }
  }
}
.#{$mPrefix}-swiper {
  position: relative;
}
@media screen and (max-width: 1550px) {
  .#{$mPrefix}-swiper {
    margin: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .#{$mPrefix}-swiper {
    padding-left: 10px;
    margin: 0;
    &-button {
      display: none;
    }
    .#{$Prefix}-media {
      &--card {
        padding: 36px 20px 24px;
      }
      &__img {
        position: absolute;
        bottom: 24px;
      }
    }
  }
  .#{$mPrefix}-carouselCustomer {
    .#{$Prefix}-media__info-desc {
      color: #fff;
      position: relative;
      &::before {
        width: 40px;
        left: 2px;
        top: -16px;
        background-size: 100%;
        background-repeat: no-repeat;
      }
    }
  }
}
