@import "../../utility/base.scss";
$borderWidth: 1px;
.#{$Prefix}-button {
  display: inline-block;
  vertical-align: middle;
  line-height: 40px;
  font-size: 14px;
  box-sizing: border-box;
  height: 42px;
  padding: 0 12px;
  min-width: 96px;
  text-align: center;
  border: $borderWidth solid;
  color: $btn-color;
  border-color: $btn-border-color;
  background: $btn-bgcolor;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  box-sizing: border-box;
  user-select: none;
  position: relative;
  border-radius: 4px;
  z-index: 0;
  & + & {
    margin-left: 12px;
  }
  &:hover {
    color: $btn-color-hover;
    border-color: $btn-border-color-primary-hover;
    background: $btn-bgcolor-primary-hover;
    transition: 0.2s all;
  }
  &--primary {
    color: $btn-color-primary;
    border-color: $btn-border-color-primary;
    background: $btn-bgcolor-primary;
    &:hover {
      color: $btn-color-primary-hover;
      border-color: $btn-border-color-primary-hover;
      background: $btn-bgcolor-primary-hover;
      transition: 0.2s all;
    }
  }
  &--empty {
    height: auto;
    line-height: initial;
    background: transparent;
    border: none;
    min-width: initial;
    padding: 0;
    &:hover {
      background: transparent;
      text-decoration: underline;
      color: $btn-color;
    }
  }
  &--empty.#{$Prefix}-button--primary {
    color: $text-color-hover;
    box-shadow: none;
  }
  // 水波
  .ripple-effect {
    overflow: hidden;
    position: absolute;
    top: -$borderWidth;
    left: -$borderWidth;
    right: -$borderWidth;
    bottom: -$borderWidth;
    z-index: -1;
    &__inner {
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.4);
      -webkit-transform: scale(0);
      transform: scale(0);
      position: absolute;
      opacity: 1;
      left: 0;
      right: 0;
      width: 0;
      height: 0;

      &.is-actived {
        animation: rippleEffect 2.5s cubic-bezier(0.23, 1, 0.32, 1);
      }
    }
  }
}
@keyframes rippleEffect {
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
