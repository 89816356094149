@import "../../utility/css/var.scss";

.#{$mPrefix}-header {
  position: relative;
  box-sizing: border-box;
  z-index: 99;
  width: 100%;
  height: 0;
  box-sizing: border-box;
  padding: 0 20px;

  &__inner {
    box-sizing: border-box;
    font-size: 0;
    max-width: $maxWidth;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 32px;
    box-sizing: content-box;
  }
  &__logo {
    width: 92px;
    height: 50px;
    img,
    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__nav {
    margin-left: 205px;
    & > ol {
      display: flex;
      align-items: center;
      & > li {
        width: 104px;
        height: 40px;
        line-height: 40px;
        margin-right: 4px;
        text-align: center;
        .title {
          font-size: 14px;
          color: #fff;
          &:hover {
            color: rgba(255,255,255,0.6);
          }
        }
      }
    }
  }
  &__navitem{
    background-image:url(../../mock/img/home/control-bt.png);
    background-size: cover;
    a{
      display: block;
      width:96px;
      height:34px;
      border-radius: 20px;
      font-weight: bold;
      margin:4px 2px 1px 4px;
      line-height: 34px;
      &:hover{
        background-color: rgba(95,198,255,0.4);
      }
    }
  }
  &__nav-btn {
    display: none;
  }
  @media screen and (max-width: $maxWidth + 160) {
    &__inner {
      max-width: $minWidth;
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media screen and (max-width: 768px) {
    height: 54px;

    &__inner {
      top: 0;
      height: 54px;
      padding: 0 16px;
      justify-content: space-between;

      background-color: #000;
    }
    &__logo {
      width: 74px;
      height: 40px;
    }
    &__nav {
      display: none;
      margin-left: 0;
      position: absolute;
      z-index: 99;
      width: 100%;
      top: 54px;
      left: 0;
      right: 0;
      background-color: #fff;
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.6);
      & > ol {
        display: block;
        & > li {
          width: 100%;
          height: 48px;
          line-height: 48px;
          margin-right: 0;
          .title {
            width: 100%;
            height: 100%;
            display: block;
            color: rgba(0, 0, 0, 0.9);
            &:hover {
              color: rgba(0, 0, 0, 0.6);
            }
          }
          & + li {
            border-top: solid 1px #eee;
          }
        }
      }
      &.is-active {
        display: block;
      }
    }
    &__nav-btn {
      display: block;
      position: relative;
      width: 24px;
      height: 24px;
      & > span {
        position: absolute;
        display: block;
        width: 20px;
        height: 2px;
        left: 2px;
        border-radius: 2px;
        background-color: #fff;
        transition: all 0.2s;
        &:nth-child(1) {
          top: 6px;
        }
        &:nth-child(2) {
          top: 11px;
        }
        &:nth-child(3) {
          top: 16px;
        }
      }
      &.is-active {
        & > span {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 11px;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 11px;
          }
        }
      }
    }
  }
}
