@import "../../utility/css/var.scss";
.#{$mPrefix}-mediaAdvantages {
  .#{$Prefix}-media {
    text-align: left;
    &__img {
      img {
        width: 128px;
        height: 128px;
        object-fit: fill;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .#{$mPrefix}-mediaAdvantages {
    .#{$Prefix}-media {
      display: flex;

      &__img {
        position: absolute;
        top: 0;
        left: 0;
        img {
          width: 36px;
          height: 36px;
        }
      }
      &__info {
        text-align: left;
        padding-top: 0;
        &-title {
          padding-left: 48px;
          font-size: 16px;
          padding-top: 6px;
        }
      }
    }
    // grid
    .#{$Prefix}-row {
      margin: -15px !important;
    }
    .#{$Prefix}-col-6 {
      padding: 15px !important;
    }
  }
}
