@import "../../utility/css/var.scss";

.dc-page {
  // banner 部分
  &-hero {
    padding-top: 320px;
    height: 800px;
    background-image: url("./../../mock/img/DocumentCenter/banner.png");
    background-size: cover;
    background-position: center bottom;
    .#{$Prefix}-hero__inner {
      padding: 0 20px;
    }
    &__title {
      font-size: 48px;
      color: rgba(255, 255, 255, 0.9);
    }
    @media screen and (max-width: 768px) {
      background-image: url("./../../mock/img/DocumentCenter/banner-m.png");
      height: 350px;
      padding-top: 110px;
      padding-left: 20px;
      padding-right: 20px;

      &__title {
        font-size: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  // 手册部分
  &-manual {
    height: 100px;
    background-color: #f5f7fa;
    &__inner {
      position: relative;
      box-sizing: border-box;
      max-width: $maxWidth;
      margin: 0 auto;
      transform: translateY(-100%);
      margin-top: -100px;
    }
    // 重置 media
    .#{$Prefix}-media {
      border: none;
      background-color: #fff;
      box-shadow: none;
      &::before {
        display: none;
      }
      &__info-title {
        font-size: 28px;
        margin-bottom: 12px;
        color: #000;
      }
      &__info-desc {
        min-height: 28px;
        margin-bottom: 22px;
      }
    }

    @media screen and (max-width: $maxWidth + 160) {
      &__inner {
        max-width: $minWidth;
      }
    }
    
    @media screen and (max-width: 768px) {
      height: auto;
      padding-bottom: 60px;
      background: #060817;
      &__inner {
        padding: 0 16px;
        transform: translateY(0);
      }
      &-moblie {
        &__card {
          background-color: #ffffff;
          box-shadow: 0 2px 12px 0 rgba(119, 135, 162, 0.2);
          border-radius: 4px;
          padding: 20px;
          box-sizing: border-box;

          &.entry {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .dc-page-manual-moblie {
              &__head {
                flex-grow: 1;
                .title {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 12px;
                  color: rgba(0, 0, 0, 0.9);
                }
                .desc {
                  font-size: 14px;
                  color: #4b5b76;
                }
              }
              &__body {
                width: 80px;
                height: 80px;
                margin-bottom: 20px;
                font-size: 0;
                img {
                  width: 100%;
                }
              }
              &__foot {
                flex-basis: 100%;
                .#{$Prefix}-button {
                  width: 100%;
                }
              }
            }
          }

          &.app {
            padding-bottom: 6px;
            .dc-page-manual-moblie {
              &__title {
                font-size: 16px;
                margin-bottom: 30px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
              }
              &__item {
                position: relative;
                display: block;
                box-sizing: border-box;
                border-top: solid 1px rgba(119, 135, 162, 0.2);
                padding: 14px 0;
                font-size: 0;
                &::after {
                  content: "";
                  width: 16px;
                  height: 16px;
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  margin-top: -8px;
                  background-image: url("./../../mock/img/DocumentCenter/icon-right.svg");
                  background-size: 100% 100%;
                }
                .icon,
                .title,
                .tips {
                  display: inline-block;
                  vertical-align: middle;
                }
                .icon {
                  width: 38px;
                  height: 38px;
                  margin-right: 10px;
                  background-size: 100%;
                }
                .title {
                  font-size: 14px;
                  margin-right: 12px;
                  color: rgba(0, 0, 0, 0.9);
                  font-weight: 500;
                }
                .tips {
                  font-size: 12px;
                  color: rgba(0, 0, 0, 0.9);
                  padding: 2px 8px;
                  background-color: #fbfbfb;
                  border: 1px solid #dddddd;
                  border-radius: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
