@import "../../utility/base.scss";

.#{$Prefix}-section {
  background-color: #060817;
  padding: 60px 20px;
  &.bg-gray {
    background-color: #060817;
  }
  &__inner {
    margin: 0 auto;
    box-sizing: border-box;
    max-width: $maxWidth;
  }
  &__hd {
    margin-bottom: 40px;
    text-align: center;
    -webkit-font-smoothing: antialiased;
  }
  // &__bd {
  // }
  &__title {
    font-weight: 500;
    font-size: 28px;
    color: #ffffff;
    line-height: 48px;
  }
}
@media screen and (max-width: $maxWidth + 160) {
  .#{$Prefix}-section {
    &__inner {
      max-width: $minWidth;
    }
  }
}
@media screen and (max-width: 768px) {
  .#{$Prefix}-section {
    padding: 32px 16px;
    &__hd {
      margin-bottom: 32px;
    }
    &__title {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
