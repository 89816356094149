@import "../../utility/css/var.scss";

.home-img {
  width: 100%;
}

.home-page {
  // 数据统计
  &-statistics {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(./img/home-page-statistics-bg.png);
    &__inner {
      max-width: $maxWidth;
      margin: 0 auto;
      padding: 0 20px;
    }
    &__desc {
      color: #ffffff;
      text-align: left;
      line-height: 28px;
      padding-top: 50px;
      margin-bottom: 30px;
      box-sizing: border-box;
      &::after {
        content: "";
        display: block;
        margin-top: 10px;
        width: 140px;
        height: 2px;
        background-color: #0052d9;
      }
    }
    &__title{
      font-size: 16px;
      text-align: center;
      line-height: 22px;
      padding-top: 4px;
      font-weight: bold;
    }
    &__info{
      padding-top:10px;
    }
    &__media {
      width: 100%;
      color: #ffffff;
      box-sizing: border-box;
      padding: 20px 0 20px 30px;
      background: #060817;
      border-right: none;
      position: relative;
      height: 100%;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border: 2px solid #242933;
        border-right: none;
        box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
          inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
        opacity: 0.5;
      }

      .media-title {
        font-size: 18 vpx;
        margin-bottom: 12px;
      }

      .media-number {
        line-height: 48px;
        font-size: 36px;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 8px;
        padding-right: 10px;
        span {
          font-size: 14px;
          padding-left: 10px;
          color: #9195ad;
        }
      }

      .media-desc {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .#{$Prefix}-col {
      &:last-child {
        .home-page-statistics__media {
          &::before {
            border-right: 2px solid #242933;
          }
        }
      }
    }
    @media screen and (max-width: $maxWidth + 160) {
      &__inner {
        max-width: $minWidth;
      }
    }
    @media screen and (max-width: 768px) {
      &__inner {
        padding: 0;
      }
      &__desc {
        padding: 20px 32px;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 0;
        &::after {
          display: none;
        }
      }
      &__media {
        padding: 20px;
        margin-bottom: 10px;
        height: calc(100% - 10px);
        .media-title {
          font-size: 14px;
          margin-bottom: 5px;
        }
        .media-number {
          font-size: 20px;
          height: auto;
          padding-top: 6px;
          padding-bottom: 4px;
          line-height: 1;
          padding-right: 0cm;
          span {
            font-size: 12px;
            padding-left: 5px;
          }
        }
        .media-desc {
          font-size: 12px;
        }
      }
      .#{$Prefix}-col {
        &:nth-child(2) {
          .home-page-statistics__media {
            border-right: none;
          }
        }
      }
    }
    @media screen and (max-width: 430px) {
      &__media {
        .media-number {
          span {
            padding-top: 12px;
            padding-left: 0;
            display: block;
          }
        }
      }
    }
  }

  // 应用场景
  &-scenes {
    background-color: #060817;
    .#{$Prefix}-section {
      &__title {
        color: rgba(255, 255, 255, 0.9);
      }
    }
    &__body {
      font-size: 0;
      margin: -20px;
    }
    &__item {
      padding: 20px;
      width: 16.6666%;
      display: inline-block;
      box-sizing: border-box;
      &:nth-child(-n + 6) {
        .home-page-scenes__synopsis {
          top: -2px;
        }
      }
      &:nth-child(n + 7) {
        .home-page-scenes__synopsis {
          bottom: -2px;
        }
      }
      &:nth-child(6),
      &:nth-child(12) {
        .home-page-scenes__synopsis {
          right: -2px;
          left: unset;
        }
      }
    }
    &__media {
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      padding: 16px;
      height: 180px;
      background-color: #060817;
      border: 2px solid #242933;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
          inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
        opacity: 0.5;
        border-radius: 4px;
      }
      .media-icon {
        position: relative;
        width: 60px;
        height: 60px;
        margin-bottom: 16px;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .media-title {
        position: relative;
        font-weight: 500;
        font-size: 20px;
        color: rgba(255, 255, 255, 0.9);
      }
      // &.is-active,
      &:hover {
        .home-page-scenes__synopsis {
          display: block;
          opacity: 1;
          visibility: visible;
          transition: 0.4s opacity;
        }
      }
    }
    &__synopsis {
      // display: none;
      opacity: 0;
      visibility: hidden;
      position: absolute;
      box-sizing: border-box;
      padding: 38px;
      z-index: 2;
      left: -2px;
      border-radius: 4px;
      width: calc(200% + 48px);
      height: calc(200% + 48px);
      color: rgba(255, 255, 255, 0.9);
      background: #060817;
      border: 2px solid #0052d9;
      box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
        inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        border-radius: 4px;
        border: 2px solid #242933;
      }
      .synopsis-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .synopsis-desc {
        font-size: 12px;
        margin-bottom: 24px;
      }
      .synopsis-lable {
        li {
          font-size: 14px;
          line-height: 32px;
          &::before {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            background-image: url("./img/icon-dot.svg");
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      padding: 32px 0;
      overflow: hidden;
      &__item {
        width: 100%;
      }
      &__body {
        margin: 0 -5px;
      }
      &__item {
        padding: 0 5px;
        &:last-child {
          .home-page-scenes__media {
            border-bottom: none;
          }
        }
      }
      &__media {
        height: auto;
        box-shadow: none;
        border-radius: 0;
        padding: 12px 0;
        border-bottom: solid 1px #13161b;
        background-color: #242933;
        display: block;
        .media-icon,
        .media-title {
          display: inline-block;
          vertical-align: middle;
        }
        .media-icon {
          width: 32px;
          height: 32px;
          margin-bottom: 0;
          margin-right: 12px;
          margin-left: 16px;
        }
        .media-title {
          font-size: 16px;
        }
        &::before {
          display: none;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          z-index: 1;
          left: auto;
          right: 16px;
          top: 20px;
          width: 16px;
          height: 16px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(./img/icon-arrow.svg);
        }
        &.is-active {
          padding-bottom: 0;
          &::after {
            transform: rotate(-180deg);
          }
        }
        &:hover {
          .home-page-scenes__synopsis {
            display: none;
          }
        }
      }
      &__synopsis {
        opacity: 1;
        visibility: visible;
        display: none;
        margin-top: 12px;
        position: relative;
        z-index: 2;
        left: unset !important;
        top: unset !important;
        bottom: unset !important;
        width: 100%;
        min-height: 100%;
        padding: 20px 16px;
        border-radius: 0;
        background-image: none;
        background-color: #13161b;
        border: none;
        box-shadow: none;
        &::before {
          border: none;
        }
        .synopsis-title {
          display: none;
        }
        .synopsis-desc {
          margin-bottom: 16px;
        }
      }
      &__media.is-active &__synopsis {
        display: block;
      }
    }
  }
  &-companion {
    display: flex;
    flex-wrap: wrap;
    img{
      margin-top:20px;
      display: block;
      width: 16%;
      margin-left:5%;
      &:nth-child(5n+1){
        margin-left:0;
      }
    }
    @media screen and (max-width: 768px) {
      img{
        margin-top:20px;
        display: block;
        width: 30%;
        margin-left:5%;
        &:nth-child(5n+1){
          margin-left:5%;
        }
        &:nth-child(3n+1){
          margin-left:0;
        }
      }
    }
  }
}
