@import "../../utility/css/var.scss";
.#{$mPrefix}-carouselRecord {
  position: relative;
  color: #fff;
  // overflow: hidden;
  .#{$mPrefix}-carouselRecord {
    &__pagination {
      position: absolute;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      &-item {
        display: inline-flex;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        color: rgba(255, 255, 255, 0.7);
        height: 32px;
        line-height: 32px;
        font-size: 20px;
        margin: 0 40px;
        padding-bottom: 2px;
        &.is-actived {
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: #fff;
          }
        }
      }
    }
    &-swiper-button-prev,
    &-swiper-button-next {
      position: absolute;
      top: 20px;
      z-index: 9;
      color: rgba(255, 255, 255, 0.9);
      font-size: 48px;
      font-weight: 500;
      opacity: 0.4;
      cursor: pointer;
      outline: none !important;
      border: none !important;
    }
    &-swiper-button-prev {
      left: 60px;
    }
    &-swiper-button-next {
      right: 60px;
    }

    @media screen and (max-width: 768px) {
      &__pagination {
        display: none;
      }
      &-swiper-button-prev,
      &-swiper-button-next {
        font-size: 24px;
        top: 20px;
      }
      &-swiper-button-prev {
        left: 12px;
      }
      &-swiper-button-next {
        right: 12px;
      }
    }
  }
}

.#{$mPrefix}-setp {
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  &--title {
    font-size: 64px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 40px;
  }
  &__inner {
    width: 54%;
    padding-right: 90px;
    box-sizing: border-box;
    margin-left: auto;
    padding-left: 55px;
    max-height: 230px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.12);
    }
  }
  &__item {
    position: relative;
    padding-bottom: 65px;
    &-point {
      position: absolute;
      top: 8px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 2;
      background-color: #fff;
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.3);
    }
    &-num {
      position: absolute;
      left: 0;
      transform: translateX(-100%);
      margin-left: -18px;
      font-size: 18px;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
    }
    &-content {
      padding-left: 30px;
      position: relative;
      text-align: justify;
      line-height: 22px;
    }
    &::before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      height: calc(100% - 2px);
      width: 1px;
      left: 4.5px;
      top: 10px;
      background-color: rgba(255, 255, 255, 0.8);
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
    &--title {
      font-size: 36px;
    }
    &__inner {
      width: 100%;
      padding-right: 10px;
      padding-left: 70px;
    }
    &__item {
      padding-bottom: 25px;
      line-height: 48px;
      &-num {
        line-height: 1;
        padding-top: 4px;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}
