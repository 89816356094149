.hidden {
  overflow: hidden;
}
.show-moblie {
  display: none;
}

@media screen and (min-width: 769px) {
  body,
  html {
    min-width: 1220px;
  }
}

@media screen and (max-width: 768px) {
  .show-pc {
    display: none!important;
  }
  .show-moblie {
    display: block!important;
  }
}

.z-doc {
  padding: 20px;
}
.z-center {
  text-align: center;
}
.z-underline {
  text-decoration: underline;
}
.z-bold {
  font-weight: bold;
}