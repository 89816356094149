// 组件字首
$Prefix: "zxc";

// 容器字首
$mPrefix: "mzxc";

// 变量公共主题色
$primary: #0052d9;
$primary-hover: #2670e8;

$maxWidth: 1420px;
$minWidth: 1180px;

// text
$text-color: #000;
$text-color-error: #e54545;
$text-color-success: #0abf5b;
$text-color-normal: #333;
$text-color-weak: #666666;
$text-color-hover: $primary;

// btn 字体
$btn-color: #000;
$btn-color-hover: #fff;
$btn-color-primary: #ffff;
$btn-color-primary-hover: #fff;
// btn 边框
$btn-border-color: #e5e5e5;
$btn-border-color-hover: $primary;
$btn-border-color-primary: $primary;
$btn-border-color-primary-hover: $primary-hover;
// btn 背景
$btn-bgcolor: #fff;
$btn-bgcolor-weak-hover: #f5f5f5;
$btn-bgcolor-hover: $primary;
$btn-bgcolor-primary: $primary;
$btn-bgcolor-primary-hover: $primary-hover;
