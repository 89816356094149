@import './swiper-vars.scss';

:root {
  --swiper-navigation-size: 44px;
  /*
  --swiper-navigation-color: var(--swiper-theme-color);
  */
}
.swiper-button-prev,
.swiper-button-next {
  // position: absolute;
  // top: 50%;
  // width: calc(var(--swiper-navigation-size) / 44 * 27);
  // height: var(--swiper-navigation-size);
  // margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  // z-index: 10;
  // cursor: pointer;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // color: var(--swiper-navigation-color, var(--swiper-theme-color));
  // &.swiper-button-disabled {
  //   opacity: 0.35;
  //   cursor: auto;
  //   pointer-events: none;
  // }
  // &:after {
  //   font-family: swiper-icons;
  //   font-size: var(--swiper-navigation-size);
  //   text-transform: none !important;
  //   letter-spacing: 0;
  //   text-transform: none;
  //   font-variant: initial;
  //   line-height: 1;
  // }
  position: absolute;
  z-index: 10;
  top: 50%;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 0;
  border: 1px solid rgba(0, 82, 217, 0.8);
  cursor: pointer;
}
.swiper-button-prev::before,
.swiper-button-next::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  // background-color: #fff;
  // border: 2px solid #fff;
  // box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  // background-image: linear-gradient(135deg, #dbe2e9, #fff);
}
.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  margin: -5px 0 0 -3px;
  width: 6px;
  height: 10px;
}
.swiper-button-next {
  left: 100%;
  margin-left: 20px;
}
.swiper-button-prev {
  right: 100%;
  margin-right: 20px;
}
.swiper-button-prev::after {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNnB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCA2IDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPnN3aXBlci1idG4tcHJldjwvdGl0bGU+CiAgICA8ZyBpZD0i6aG16Z2iLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJzd2lwZXItYnRuLXByZXYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuMDAwMDAwLCA1LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuMDAwMDAwLCAtNS4wMDAwMDApIHRyYW5zbGF0ZSgtMC4wMDAwMDAsIC0wLjAwMDAwMCkiIGZpbGw9IiNCQUMxQzkiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjMuNiA1IDAgMS4yNTA5MDkwOSAxLjIgMCA2IDUgNC44IDYuMjUwOTA5MDkgMS4yIDEwIDAgOC43NDkwOTA5MSI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
}
.swiper-button-prev:hover::after {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNnB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCA2IDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPnN3aXBlci1idG4tcHJldjwvdGl0bGU+CiAgICA8ZyBpZD0i6aG16Z2iLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJzd2lwZXItYnRuLXByZXYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMuMDAwMDAwLCA1LjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTMuMDAwMDAwLCAtNS4wMDAwMDApIHRyYW5zbGF0ZSgtMC4wMDAwMDAsIC0wLjAwMDAwMCkiIGZpbGw9IiMwMDUyZDkiIGZpbGwtcnVsZT0ibm9uemVybyI+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjMuNiA1IDAgMS4yNTA5MDkwOSAxLjIgMCA2IDUgNC44IDYuMjUwOTA5MDkgMS4yIDEwIDAgOC43NDkwOTA5MSI+PC9wb2x5Z29uPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
}
.swiper-button-next::after {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNnB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCA2IDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1OSAoODYxMjcpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0i5pyA57uIIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDE4LjAwMDAwMCwgLTkyLjAwMDAwMCkiIGZpbGw9IiNCQUMxQzkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDE2LjAwMDAwMCwgOTIuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSI1LjYgNSAyIDEuMjUwOTA5MDkgMy4yIDAgOCA1IDYuOCA2LjI1MDkwOTA5IDMuMiAxMCAyIDguNzQ5MDkwOTEiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
}
.swiper-button-next:hover::after {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNnB4IiBoZWlnaHQ9IjEwcHgiIHZpZXdCb3g9IjAgMCA2IDEwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1OSAoODYxMjcpIC0gaHR0cHM6Ly9za2V0Y2guY29tIC0tPgogICAgPHRpdGxlPlBhdGg8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0i5pyA57uIIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDE4LjAwMDAwMCwgLTkyLjAwMDAwMCkiIGZpbGw9IiMwMDUyZDkiPgogICAgICAgICAgICA8ZyBpZD0iR3JvdXAtMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDE2LjAwMDAwMCwgOTIuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSI1LjYgNSAyIDEuMjUwOTA5MDkgMy4yIDAgOCA1IDYuOCA2LjI1MDkwOTA5IDMuMiAxMCAyIDguNzQ5MDkwOTEiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
}
// .swiper-button-prev,
// .swiper-container-rtl .swiper-button-next {
//   &:after {
//     content: 'prev';
//   }
//   left: 10px;
//   right: auto;
// }
// .swiper-button-next,
// .swiper-container-rtl .swiper-button-prev {
//   &:after {
//     content: 'next';
//   }
//   right: 10px;
//   left: auto;
// }

// @each $navColorName, $navColorValue in $colors {
//   .swiper-button-prev,
//   .swiper-button-next {
//     &.swiper-button-#{'' + $navColorName} {
//       --swiper-navigation-color: #{'' + $navColorValue};
//     }
//   }
// }
// .swiper-button-lock {
//   display: none;
// }
