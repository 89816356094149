@import "../../utility/css/var.scss";
.#{$mPrefix}-carouseBanner {
  position: relative;
  color: #fff;
  overflow: hidden;
  .#{$mPrefix}-carouseBanner {
    &__swiper {
      margin: 0;
    }
    &__item {
      height: 100vh;
      background-color: #000;
      &-img {
        height: 100%;
        img, video {
          width: 100%;
          height:100%;
          object-fit: cover;
        }
      }

      &-info {
        position: absolute;
        bottom: 0;
        height:280px;
        width:100%;
        background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
      }
      &-detail {
        // padding: 0 20px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: $maxWidth;
        display: flex;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        padding-bottom: 132px;
        &--title {
          font-size: 42px;
          color: #ffffff;
          line-height: 64px;
          font-weight: 600;
        }
        &--des {
          font-size: 42px;
          color: #ffffff;
          line-height: 64px;
          padding-bottom: 16px;
        }
        &--info {
          font-size:14px;
          color: #ffffff;
        }
      }
    }
    &__pagination {
      position: absolute;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
      width: $maxWidth;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 80px;
      &-item {
        display: inline-flex;
        width: 30px;
        height: 4px;
        cursor: pointer;
        margin: 0 5px;
        padding: 8px 0;
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          background: #ffffff;
          border-radius: 3px;
        }
        &.is-actived::before {
          background-color: $primary;
        }
      }
    }
  }
}
@media screen and (max-width: $maxWidth + 160) {
  .#{$mPrefix}-carouseBanner {
    .#{$mPrefix}-carouseBanner {
      &__item {
        &-detail {
          width: $minWidth;
        }
      }
    }
  }
}
// @media screen and (max-width: $maxWidth) {
//   .#{$mPrefix}-carouseBanner {
//     .#{$mPrefix}-carouseBanner {
//       &__item {
//         &-detail {
//           width: 90%;
//         }
//       }
//     }
//   }
// }
@media screen and (max-width: 768px) {
  .#{$mPrefix}-carouseBanner {
    .#{$mPrefix}-carouseBanner {
      &__item {
        height: 220px;
      }
      &__pagination {
        bottom: 24px;
      }
      &__item {
        &-img { 
          img, video {
            object-fit: cover;
            width:100%;
            object-position: center;
          }
        }
        &-info {
          // padding-top: 27px; //header的一半高度
          height: 30%;
          width: 100%;
          // &--title {
          //   font-size: 16px;
          //   color: #ffffff;
          //   text-align: center;
          //   line-height: 22px;
          // }
          // &--des {
          //   font-size: 16px;
          //   text-align: center;
          //   line-height: 22px;
          //   padding-top: 8px;
          // }
        }
      }
    }
  }
}
