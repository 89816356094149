@import "../../utility/base.scss";
.#{$Prefix}-media {
  text-align: center;
  position: relative;
  box-sizing: border-box;

  &--shadow {
    padding: 30px;
    background: #f3f5f8;
    box-shadow: 0 2px 12px 0 rgba(119, 135, 162, 0.2);
    background-color: #fff;
  }

  &--card {
    padding: 30px;
    background: #060817;
    border: 2px solid rgba(0, 82, 217, 0.5);
    box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
      inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
    border-radius: 4px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 10px;
      top: 10px;
      right: 10px;
      bottom: 10px;
      border: 2px solid rgba(54, 62, 77, 0.5);
      border-radius: 4px;
      box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
        inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
      opacity: 0.5;
    }
  }
  &--hover {
    transition: transform 0.2s linear;
    &:hover {
      cursor: pointer;
      transform: translate3d(0, -3px, 0);

      .#{$Prefix}-media__info-title {
        color: $primary;
      }
    }
  }

  &--radius {
    border-radius: 4px;
  }
  .double {
    transform: scale(0.5);
  }
  // 水平布局
  &--horizon {
    display: flex;
    text-align: left;

    .#{$Prefix}-media {
      &__img {
        margin-right: 20px;
        height: auto;
        align-items: flex-start;
      }

      &__info {
        padding-bottom: 0;
        padding-top: 0;

        &-title {
          font-size: 20px;
          font-weight: 500;
        }
        &-des {
          font-size: 14px;
          color: #495770;
          line-height: 24px;
        }
      }

      &-tag {
        &--span {
        }
      }
    }
  }
  // 垂直居中
  &--vertical {
    align-items: center;
  }
  // 取反
  &--opposite {
    flex-direction: row-reverse;
    .#{$Prefix}-media {
      &__img {
        margin-right: 0;
        margin-left: 60px;
      }
    }
  }
  // 默认布局
  &__img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 0;
    position: relative;
    &.is-card {
      padding: 12px 20px;
      background: #060817;
      border: 2px solid #242933;
      box-shadow: inset 8px 8px 20px 0 rgba(18, 21, 26, 0.6),
        inset -8px -8px 20px 0 rgba(54, 62, 77, 0.5);
    }
    &.is-radius {
      border-radius: 50%;
    }
    &.bg-white {
      background: #fff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border: none;
      height: 80px;
      width: 80px;
      img {
        width: 40px;
        object-fit: contain;
      }
    }
    &.bg-trans{
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border: none;
      height: 80px;
      width: 80px;
      img {
        object-fit: contain;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    padding-top: 16px;
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;

    &-title {
      font-size: 20px;
      line-height: 26px;
      color: #fff;
      font-weight: 500;
    }
    &-subTitle {
      font-size: 14px;
      line-height: 26px;
      color: #fff;
    }
    &-desc {
      font-size: 14px;
      color: #9195ad;
      font-weight: 400;
      line-height: 24px;

      span {
        display: inline-block;
        text-align: left;
      }
    }

    &-title + &-desc {
      margin-top: 16px;
    }
    &-subTitle + &-desc {
      margin-top: 16px;
    }

    &-tag {
      margin-top: 24px;

      &--span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
        padding-left: 24px;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position: left center;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-link {
      margin-top: 12px;
    }
    &-source {
      font-size: 16px;
      color: #9195ad;
      text-align: right;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;
      &--line {
        width: 26px;
        height: 1px;
        background-color: #9195ad;
        display: inline-block;
        margin-right: 8px;
        margin-right: 20px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .#{$Prefix}-media {
    &__info {
      &-title {
        font-size: 16px;
      }
      &-des {
        font-size: 14px;
      }
    }
  }
}
