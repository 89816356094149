@import "../../utility/base.scss";
.#{$Prefix}-grid {
  overflow: hidden;
}
.#{$Prefix}-row {
  display: flex;
  flex-wrap: wrap;
}
.#{$Prefix}-col {
  min-height: 1px;
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
}
[class*="#{$Prefix}-col-"] {
  flex-grow: 0;
  flex-shrink: 0;
}
.#{$Prefix}-col-1-2 {
  width: 50%;
}
.#{$Prefix}-col-1-3 {
  width: 33.333%;
}
.#{$Prefix}-col-1-4 {
  width: 25%;
}
.#{$Prefix}-col-1-5 {
  width: 20%;
}
.#{$Prefix}-col-1 {
  width: 4.166666667%;
}
.#{$Prefix}-col-2 {
  width: 8.333333333%;
}
.#{$Prefix}-col-3 {
  width: 12.5%;
}
.#{$Prefix}-col-4 {
  width: 16.66666667%;
}
.#{$Prefix}-col-5 {
  width: 20.833333333%;
}
.#{$Prefix}-col-6 {
  width: 25%;
}
.#{$Prefix}-col-7 {
  width: 29.166666667%;
}
.#{$Prefix}-col-8 {
  width: 33.33333333%;
}
.#{$Prefix}-col-9 {
  width: 37.5%;
}
.#{$Prefix}-col-10 {
  width: 41.66666667%;
}
.#{$Prefix}-col-11 {
  width: 45.833333333%;
}
.#{$Prefix}-col-12 {
  width: 50%;
}
.#{$Prefix}-col-13 {
  width: 54.166666667%;
}
.#{$Prefix}-col-14 {
  width: 58.33333333%;
}
.#{$Prefix}-col-15 {
  width: 62.5%;
}
.#{$Prefix}-col-16 {
  width: 66.66666667%;
}
.#{$Prefix}-col-17 {
  width: 70.833333333%;
}
.#{$Prefix}-col-18 {
  width: 75%;
}
.#{$Prefix}-col-19 {
  width: 79.166666667%;
}
.#{$Prefix}-col-20 {
  width: 83.33333333%;
}
.#{$Prefix}-col-21 {
  width: 87.5%;
}
.#{$Prefix}-col-22 {
  width: 91.66666667%;
}
.#{$Prefix}-col-23 {
  width: 95.833333333%;
}
.#{$Prefix}-col-24 {
  width: 100%;
}

.#{$Prefix}-col--pull-1 {
  margin-left: 4.166666667%;
}
.#{$Prefix}-col--pull-2 {
  margin-left: 8.333333333%;
}
.#{$Prefix}-col--pull-3 {
  margin-left: 12.5%;
}
.#{$Prefix}-col--pull-4 {
  margin-left: 16.66666667%;
}
.#{$Prefix}-col--pull-5 {
  margin-left: 20.833333333%;
}
.#{$Prefix}-col--pull-6 {
  margin-left: 25%;
}
.#{$Prefix}-col--pull-7 {
  margin-left: 29.166666667%;
}
.#{$Prefix}-col--pull-8 {
  margin-left: 33.33333333%;
}
.#{$Prefix}-col--pull-9 {
  margin-left: 37.5%;
}
.#{$Prefix}-col--pull-10 {
  margin-left: 41.66666667%;
}
.#{$Prefix}-col--pull-11 {
  margin-left: 45.833333333%;
}
.#{$Prefix}-col--pull-12 {
  margin-left: 50%;
}
.#{$Prefix}-col--pull-13 {
  margin-left: 54.166666667%;
}
.#{$Prefix}-col--pull-14 {
  margin-left: 58.33333333%;
}
.#{$Prefix}-col--pull-15 {
  margin-left: 62.5%;
}
.#{$Prefix}-col--pull-16 {
  margin-left: 66.66666667%;
}
.#{$Prefix}-col--pull-17 {
  margin-left: 70.833333333%;
}
.#{$Prefix}-col--pull-18 {
  margin-left: 75%;
}
.#{$Prefix}-col--pull-19 {
  margin-left: 79.166666667%;
}
.#{$Prefix}-col--pull-20 {
  margin-left: 83.33333333%;
}
.#{$Prefix}-col--pull-21 {
  margin-left: 87.5%;
}
.#{$Prefix}-col--pull-22 {
  margin-left: 91.66666667%;
}
.#{$Prefix}-col--pull-23 {
  margin-left: 95.833333333%;
}
.#{$Prefix}-col--pull-24 {
  margin-left: 100%;
}

.#{$Prefix}-col--push-1 {
  margin-right: 4.166666667%;
}
.#{$Prefix}-col--push-2 {
  margin-right: 8.333333333%;
}
.#{$Prefix}-col--push-3 {
  margin-right: 12.5%;
}
.#{$Prefix}-col--push-4 {
  margin-right: 16.66666667%;
}
.#{$Prefix}-col--push-5 {
  margin-right: 20.833333333%;
}
.#{$Prefix}-col--push-6 {
  margin-right: 25%;
}
.#{$Prefix}-col--push-7 {
  margin-right: 29.166666667%;
}
.#{$Prefix}-col--push-8 {
  margin-right: 33.33333333%;
}
.#{$Prefix}-col--push-9 {
  margin-right: 37.5%;
}
.#{$Prefix}-col--push-10 {
  margin-right: 41.66666667%;
}
.#{$Prefix}-col--push-11 {
  margin-right: 45.833333333%;
}
.#{$Prefix}-col--push-12 {
  margin-right: 50%;
}
.#{$Prefix}-col--push-13 {
  margin-right: 54.166666667%;
}
.#{$Prefix}-col--push-14 {
  margin-right: 58.33333333%;
}
.#{$Prefix}-col--push-15 {
  margin-right: 62.5%;
}
.#{$Prefix}-col--push-16 {
  margin-right: 66.66666667%;
}
.#{$Prefix}-col--push-17 {
  margin-right: 70.833333333%;
}
.#{$Prefix}-col--push-18 {
  margin-right: 75%;
}
.#{$Prefix}-col--push-19 {
  margin-right: 79.166666667%;
}
.#{$Prefix}-col--push-20 {
  margin-right: 83.33333333%;
}
.#{$Prefix}-col--push-21 {
  margin-right: 87.5%;
}
.#{$Prefix}-col--push-22 {
  margin-right: 91.66666667%;
}
.#{$Prefix}-col--push-23 {
  margin-right: 95.833333333%;
}
.#{$Prefix}-col--push-24 {
  margin-right: 100%;
}
// $a: '%';
// @media (max-width: 768px) {
//   .#{$Prefix}-col-12 {
//     width: 100%;
//   }
//   @for $i from 1 through (24) {
//     .#{$Prefix}-col--phone-#{$i} {
//       width: 100/24 * $i#{'%'};
//     }
//   }
// }
@media (max-width: 768px) {
  .zxc-col-12 {
    width: 100%;
  }

  .zxc-col--phone-1 {
    width: 4.16667%;
  }

  .zxc-col--phone-2 {
    width: 8.33333%;
  }

  .zxc-col--phone-3 {
    width: 12.5%;
  }

  .zxc-col--phone-4 {
    width: 16.66667%;
  }

  .zxc-col--phone-5 {
    width: 20.83333%;
  }

  .zxc-col--phone-6 {
    width: 25%;
  }

  .zxc-col--phone-7 {
    width: 29.16667%;
  }

  .zxc-col--phone-8 {
    width: 33.33333%;
  }

  .zxc-col--phone-9 {
    width: 37.5%;
  }

  .zxc-col--phone-10 {
    width: 41.66667%;
  }

  .zxc-col--phone-11 {
    width: 45.83333%;
  }

  .zxc-col--phone-12 {
    width: 50%;
  }

  .zxc-col--phone-13 {
    width: 54.16667%;
  }

  .zxc-col--phone-14 {
    width: 58.33333%;
  }

  .zxc-col--phone-15 {
    width: 62.5%;
  }

  .zxc-col--phone-16 {
    width: 66.66667%;
  }

  .zxc-col--phone-17 {
    width: 70.83333%;
  }

  .zxc-col--phone-18 {
    width: 75%;
  }

  .zxc-col--phone-19 {
    width: 79.16667%;
  }

  .zxc-col--phone-20 {
    width: 83.33333%;
  }

  .zxc-col--phone-21 {
    width: 87.5%;
  }

  .zxc-col--phone-22 {
    width: 91.66667%;
  }

  .zxc-col--phone-23 {
    width: 95.83333%;
  }

  .zxc-col--phone-24 {
    width: 100%;
  }
}
