@import "../../utility/css/var.scss";

.#{$mPrefix}-carouselHonor {
  position: relative;
  .#{$Prefix}-media {
    &--card {
      border: none;
      &::before {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #0e121e;
        border: 2px solid #1c1e23;
        box-shadow: inset 8px 8px 35px 0 rgba(0, 0, 0, 0.6),
          inset -3px -8px 26px 0 rgba(62, 66, 74, 0.5);
      }
    }
    &__info-title {
      font-size: 16px;
    }
    &__img {
      height: 200px;
      width: 160px;
    }
  }
}
@media screen and (max-width: 1550px) {
  .#{$mPrefix}-carouselHonor {
    margin: 0 40px;
  }
}
@media screen and (max-width: 768px) {
  .#{$mPrefix}-carouselHonor {
    padding-left: 10px;
    margin: 0;
    &-button {
      display: none;
    }
  }
}

// 弹窗
.#{$Prefix}-imgDialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.7);
  }
  &__close {
    z-index: 4;
    background-image: url('./img/close.svg');
    background-size: cover;
    position: absolute;
    top: 36px;
    right: 30px;
    border-radius: 50%;
    padding: 4px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      transition: 0.3s all;
      opacity: 1;
    }
  }
  &__inner {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 0;
  }
  .img {
    display: inline-block;
    width: 500px;
  }
  @media screen and (max-width:768px) {
    &__inner {
      width: 100%;
    }
    .img {
      width: 80%;
    }
  }
}
